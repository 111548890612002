<template>
  <div>
    <form @submit.prevent="toggleModal">
      <loading-button :is-loading="false">Add Packer </loading-button>
    </form>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="assign-to"
      size="xs"
      modal-title="Add a packer"
    >
      <transition
        enter-active-class="miniFadeInUp"
        leave-active-class="miniFadeOutDown"
        mode="out-in"
      >
        <div v-if="details" key="details">
          <div class="pb-4 mb-4">
            Below are the users login details, please make a note of this as it
            will not be shown again
          </div>
          <div
            class="bg-indigo-100 text-center py-6 space-y-4 break-words font-mono p-2 text-indigo-800 rounded-lg"
          >
            <div>
              {{ details.packer.email }}
            </div>
            <div>
              {{ details.packer.ps }}
            </div>
          </div>
        </div>
        <div v-else key="form">
          <div class="space-y-4 mb-6 flex flex-col w-full">
            <!-- create a form that accepts first name and last name-->
            <div class="w-full">
              <input
                type="text"
                v-model="user.firstname"
                class="w-full"
                placeholder="First Name"
              />
            </div>
            <div class="w-full">
              <input
                type="text"
                class="w-full"
                v-model="user.lastname"
                placeholder="Last Name"
              />
            </div>
          </div>

          <form @submit.prevent="add">
            <loading-button :is-loading="isLoading" :is-enabled="true">
              Add Packer
            </loading-button>
          </form>
        </div>
      </transition>
    </modal>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/ui/Modal.vue';

export default {
  components: {
    LoadingButton,
    Modal
  },
  props: {},
  data() {
    return {
      modalVisible: false,

      isLoading: false,
      user: {
        firstname: '',
        lastname: ''
      },
      details: null
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    hasCoupons() {
      return this.userProfile?.subscription?.subscription?.coupons.length > 0;
    },
    activeCoupons() {
      return this.userProfile?.subscription?.subscription?.coupons;
    }
  },
  methods: {
    ...mapActions({
      addPacker: 'addPacker',
      getPortalUsers: 'getPortalUsers'
    }),
    reset() {
      this.details = null;
      this.user = {
        firstname: '',
        lastname: ''
      };
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
      if (this.modalVisible) {
        this.reset();
      }
    },
    add() {
      this.isLoading = true;
      this.addPacker(this.user).then(response => {
        this.isLoading = false;
        this.details = response;
        this.getPortalUsers();
      });
    },
    remove(key) {
      this.removeCoupon({
        subid: this.userProfile.subscription.subscription.subscriptionid,
        uuid: this.$route.params.id,
        coupon: key
      }).then(() => {
        this.isLoading = false;
        this.getSubscription(this.$route.params.id);
      });
    }
  },

  mounted() {
    // clear the user object
  }
};
</script>

<style lang="scss"></style>
