<template>
  <div class="border rounded-lg p-4 w-full">
    <div class=" font-semibold text-lg mb-4 flex items-center">
      <div class="flex-grow">Packing Issues</div>
      <div class="font-normal text-gray-400 font-mono text-sm leading-none">
        MTD
      </div>
    </div>
    <div v-if="packingMistakes.length > 0" class="space-y-4">
      <div
        v-for="packer in packingMistakes"
        :key="packer.id"
        class="flex space-x-4 items-center"
      >
        <avatar size="sm" :email="packer.email" />
        <div class="flex-grow">{{ packer.name }}</div>
        <div class="text-gray-500">{{ packer.count }}</div>
      </div>
    </div>
    <div v-else class=" h-24 text-gray-400 flex items-center justify-center">
      No packing issues!
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapActions } from 'vuex';
import Avatar from './Avatar.vue';
export default {
  components: { Avatar },
  name: 'PackingMistakes',
  data() {
    return {
      packingMistakes: []
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      getPackingMistakes: 'getPackingMistakes'
    })
  },
  mounted() {
    const startDate = new Date();
    startDate.setDate(1);
    const endDate = new Date();

    // format both start and end dates to the format YYYY-MM-DD
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    const data = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    };

    this.getPackingMistakes(data).then(res => {
      // this.packingMistakes = res;
      const order = res;

      order.sort((a, b) => {
        return b.count - a.count;
      });

      this.packingMistakes = order;
    });
  }
};
</script>

<style></style>
