<template>
  <div
    class="relative overflow-hidden pb-16 w-full group"
    ref="statWrapper"
    :class="{ 'border rounded-lg p-4 pt-3  w-48': !child }"
  >
    <div
      class="default-transition"
      :class="showHelper ? 'opacity-5' : 'opacity-100'"
    >
      <div class="flex space-x-2 items-center">
        <div class="font-semibold text-xl flex-grow">
          {{
            !isPercentage
              ? Number(
                  tooltip.isHidden ? getValue() : tooltip.value
                ).toLocaleString()
              : `${Number(tooltip.isHidden ? getValue() : tooltip.value)}%`
          }}
          <span v-if="suffix">{{ suffix }}</span>
        </div>
        <span
          v-if="hasHelper"
          class="hidden -top-1 group-hover:block cursor-pointer hover:text-gray-600 text-gray-500"
          @mouseover="showHelperAction"
          @mouseout="hideHelperAction"
        >
          <font-awesome-icon
            fixed-width
            class="text-sm"
            :icon="['fal', 'circle-info']"
        /></span>
      </div>
      <div class="text-gray-500 mt-1 font-mono text-xs">
        {{ tooltip.isHidden ? label : tooltip.label }}
      </div>
      <div v-if="!stat" class="absolute bottom-1 left-0  w-full rounded ">
        <svg
          :ref="key"
          :id="key"
          class="sparkline"
          height="50"
          stroke-width="2"
        ></svg>
        <span class="tooltip" hidden="true"></span>
      </div>
    </div>
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div
        class="absolute bottom-2  right-2 left-2 p-2 pb-1 font-mono text-gray-600   rounded text-xs"
        v-if="showHelper"
      >
        {{ hasHelper.description }}
      </div>
    </transition>
  </div>
</template>

<script>
import sparkline from '@fnando/sparkline';
import moment from 'moment';
import utils from '@/utils';
import { last, sumBy, isNaN } from 'lodash/fp';
import { mapGetters } from 'vuex';
export default {
  props: {
    stat: [Number, String],
    label: String,
    child: Boolean,
    data: Array,
    type: String,
    showTotal: Boolean,
    suffix: String,
    isPercentage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tooltip: {
        label: '',
        value: '',
        isHidden: true
      },
      key: utils.createKey('sparkline', 6),
      showHelper: false,
      timeout: null
    };
  },
  computed: {
    ...mapGetters({
      analyticsStats: 'analyticsStatsGetter',
      analyticsHelpers: 'analyticsHelpersGetter'
    }),
    latestValue() {
      if (this.showTotal) {
        return sumBy(function(o) {
          return o.value ? o.value : 0;
        }, this.dataValues);
      } else {
        if (this.analyticsStats.length > 0) {
          const val = last(this.analyticsStats).data[this.type];
          if (this.suffix === '%') {
            return this.percentage(val);
          }
          return val;
        }
        return false;
      }
    },
    dataValues() {
      const dataValues = [];
      this.analyticsStats.forEach(d => {
        dataValues.push({
          value: isNaN(Number(d.data[this.type]))
            ? 0
            : this.suffix === '%'
            ? this.percentage(Number(d.data[this.type]))
            : Number(d.data[this.type]),
          label: moment(d.date).format('Do MMM, YYYY')
        });
      });
      return dataValues;
    },
    hasHelper() {
      return this.analyticsHelpers[this.type] || null;
    }
  },
  watch: {
    dataValues(value) {
      if (value) {
        this.drawSparkline();
      }
    }
  },
  methods: {
    getValue() {
      if (this.stat) {
        return Number(this.stat);
      } else {
        return Number(this.latestValue);
      }
    },
    percentage(value) {
      return value * 100;
    },
    drawSparkline() {
      const self = this;
      const options = {
        onmousemove(event, datapoint) {
          self.tooltip.isHidden = false;
          self.tooltip.value = datapoint.value;
          self.tooltip.label = datapoint.label;
        },

        onmouseout() {
          self.tooltip.isHidden = true;
        }
      };
      const sparklineElem = this.$refs[this.key];
      const wrapper = this.$refs.statWrapper;

      const width = wrapper.clientWidth;
      sparklineElem.setAttribute('width', width);

      sparkline(sparklineElem, this.dataValues, options);
    },
    showHelperAction() {
      this.timeout = setTimeout(() => {
        this.showHelper = true;
      }, 500);
    },
    hideHelperAction() {
      clearTimeout(this.timeout);
      this.showHelper = false;
    }
  },
  mounted() {
    if (this.data) {
      this.drawSparkline();
    }
  }
};
</script>

<style lang="css" scoped>
/* line with highlight area */
.sparkline {
  @apply stroke-current text-indigo-400 w-full;

  fill: #e0e7ff;
}
.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 2px 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 9999;
}
</style>
