<template>
  <div class="px-4 py-4 h-full">
    <div v-if="isAdmin && !isCustomerService" class="grid grid-cols-12 gap-4">
      <div v-if="coreStats" class="col-span-full sm:col-span-12 space-y-4">
        <div class="border w-full rounded-lg p-4 space-y-4">
          <div class="font-semibold text-lg">Subscriptions</div>
          <div class="sticky left-0 space-x-8 flex items-center">
            <div class="flex-grow">
              <div class="text-3xl font-semibold flex space-x-2 items-center">
                <div>{{ formatThousands(getTotalSub) }}</div>
                <div class="text-xs" v-if="isToday">
                  <div v-html="getChange"></div>
                </div>
              </div>
              <div class="text-gray-500 font-mono text-xs">Total</div>
            </div>
            <div class="w-16 mt-2">
              <div class="text-xl font-semibold">
                {{ formatThousands(Number(getStat('notpausedSubscribers'))) }}
              </div>
              <div class="flex items-center space-x-2">
                <div class="w-1 h-4 rounded flex bg-indigo-400"></div>
                <div class="text-gray-500 font-mono text-xs">Active</div>
              </div>
            </div>

            <div class="w-16 mt-2">
              <div class="text-xl font-semibold">
                {{ formatThousands(Number(getStat('pausedSubscribers'))) }}
              </div>
              <div class="flex items-center space-x-2">
                <div class="w-1 h-4 rounded flex bg-indigo-300"></div>
                <div class="text-gray-500 font-mono text-xs">Paused</div>
              </div>
            </div>
          </div>
          <div class="flex items-stretch"></div>

          <percentage-bar :total="getTotalSub" :data="subscriberCounts" />
        </div>
      </div>
      <div class="col-span-full border w-full rounded-lg p-1 py-1">
        <subscriber-cats />
      </div>
      <div class="p-4 border rounded-lg w-full col-span-12" v-if="isAdmin">
        <div class="font-semibold text-lg mb-4 sticky left-0">
          Monthly Dispatch - {{ month }}
        </div>
        <div class="">
          <monthly-dispatch></monthly-dispatch>
        </div>
      </div>
      <div
        class="flex items-center flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 col-span-12"
      >
        <div class="flex-grow">
          <radio-buttons
            :options="['today', 'week', 'month', 'year', 'all']"
            v-model="activeTime"
          />
        </div>
        <div class="text-gray-400 text-xs font-mono">
          last updated {{ timeUpdated | timeago }}
        </div>
      </div>
      <div
        v-if="analyticsStats"
        class="grid grid-cols-12 gap-4 col-span-12 pb-4"
      >
        <!-- <div class="col-span-4 text-lg font-semibold">Taster Packs</div> -->
        <statbox
          type="trialpacks"
          label="Trials Started"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
          :show-total="!isToday"
        />
        <statbox
          type="totalSubscribers"
          label="Subscriptions"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
        />
        <statbox
          type="pausedtotal"
          label="Paused"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
          :show-total="!isToday"
        />
        <statbox
          type="cancellations"
          label="Cancellations"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
          :show-total="!isToday"
        />
        <statbox
          type="averageconversiondays"
          label="Conversion (Days)"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
        />
        <statbox
          type="freesamplesAverage60"
          label="Samples Avg (60 days)"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
        />
        <statbox
          type="freesamplesUiDcount60"
          label="Samples Count (60 days)"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
        /><statbox
          type="bonusfoodconversion"
          label="Samples Conversions"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
          is-percentage
        />

        <statbox
          type="newsubscribers"
          label="New Subscribers"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
          :show-total="!isToday"
        />
        <statbox
          type="newsubscribersMtd"
          label="New Subscribers - MTD"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
        />
        <statbox
          type="totalboxes"
          label="Boxes Sent - MTD"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
        />

        <statbox
          type="manualOrders"
          label="Manual Orders Created"
          class="col-span-6 sm:col-span-3"
          :data="analyticsStats"
          :show-total="!isToday"
        />

        <div class="col-span-full grid grid-cols-12 gap-4">
          <credit-list class="col-span-full sm:col-span-4" />
          <trials-ending-list class="col-span-full sm:col-span-4" />
          <packing-mistakes class="col-span-full sm:col-span-4" />
          <latest-sub-orders class="col-span-full sm:col-span-full" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full h-full flex flex-grow flex-col items-center justify-center text-3xl font-semibold space-y-6"
    >
      <div class="w-48"><img :src="`images/${catWelcome}.webp`" /></div>
      <div>{{ welcome }}</div>
    </div>
  </div>
</template>

<script>
import Statbox from '@/components/common/ui/Statbox';
import PercentageBar from '@/components/common/ui/PercentageBar';
import CreditList from '@/components/common/ui/CreditList';
import TrialsEndingList from '@/components/common/ui/TrialsEndingList';
import MonthlyDispatch from '@/components/common/ui/MonthlyDispatch';

import { mapGetters } from 'vuex';
import utils from '@/utils';
import { first, orderBy, sample } from 'lodash/fp';
import moment from 'moment';
import RadioButtons from '@/components/common/ui/RadioButtons';
import PackingMistakes from '@/components/common/ui/PackingMistakes.vue';
import LatestSubOrders from '@/components/common/ui/LatestSubOrders.vue';
import SubscriberCats from '@/components/common/ui/SubscriberCats.vue';

export default {
  props: {
    welcome: {
      type: String
    }
  },
  components: {
    Statbox,
    PercentageBar,
    CreditList,
    TrialsEndingList,

    RadioButtons,
    MonthlyDispatch,
    PackingMistakes,
    LatestSubOrders,
    SubscriberCats
  },
  data() {
    return {
      activeTime: 'today'
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      coreStats: 'coreStatsGetter',
      analyticsStats: 'analyticsStatsGetter',
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService'
    }),
    catWelcome() {
      return sample(['cat-astro', 'cat-laptop']);
    },
    getTotalSub() {
      return (
        Number(this.getStat('notpausedSubscribers')) +
        Number(this.getStat('pausedSubscribers'))
      );
    },
    month() {
      return moment().format('MMMM');
    },
    timeUpdated() {
      const m = moment();
      return m.startOf('hour');
    },
    subscriberCounts() {
      const counts = [
        {
          label: 'Active Subscribers',
          value: this.getStat('notpausedSubscribers'),
          color: 'bg-indigo-400'
        },
        {
          label: 'Paused Subscribers',
          value: this.getStat('pausedSubscribers'),
          color: 'bg-indigo-300'
        }
      ];
      return counts;
    },
    cats() {
      const cats = [
        {
          count: 3,
          value: Number(this.getStat('threeCats'))
        },
        {
          count: 2,
          value: Number(this.getStat('twoCats'))
        },
        {
          count: 1,
          value: Number(this.getStat('oneCat'))
        }
      ];

      return orderBy(['value'], ['desc'], cats);
    },
    today() {
      return moment();
    },
    isToday() {
      return this.activeTime === 'today';
    },
    getChange() {
      const firstVal = first(this.analyticsStats)?.data.totalSubscribers;

      const lastVal = Number(this.getTotalSub);
      if (lastVal === firstVal) {
        return `<span class="text-gray-400">- 0 </span>`;
      } else {
        if (lastVal > firstVal) {
          return `<span class="text-green-500">&uarr; ${
            lastVal - firstVal
          } </span>`;
        } else {
          return `<span class="text-red-500">&darr; ${
            firstVal - lastVal
          } </span>`;
        }
      }
    }
  },
  methods: {
    getStat(prop) {
      return this.coreStats[prop].data.toLocaleString();
    },
    formatThousands(val) {
      return val.toLocaleString();
    },

    getPercentageOf(total, val) {
      return utils.getPercentageOf(total, val).toFixed();
    },

    getAnayltics() {
      let start = moment().subtract(1, 'days').format('YYYY-MM-DD');
      if (this.activeTime !== 'today') {
        if (this.activeTime === 'all') {
          start = '2021-01-01';
        } else {
          console.log('not today');
          start = moment()
            .isoWeekday(1)
            .startOf(this.activeTime)
            .format('YYYY-MM-DD');
        }
      }

      this.$store.dispatch('getAnalyticsStats', {
        start: start,
        end: moment().format('YYYY-MM-DD')
      });
    }
  },
  watch: {
    activeTime(value) {
      if (value) {
        this.getAnayltics();
      }
    }
  },
  mounted() {
    if (!this.coreStats) {
      this.$store.dispatch('getCoreStats');
    }

    this.getAnayltics();
    this.$store.dispatch('getChurnList', this.$store.state.churn.defaultDays);
  }
};
</script>

<style lang="css" scoped></style>
