<template>
  <div class="px-8">
    <div class="text-xl font-semibold py-5 flex items-center justify-between">
      Settings
    </div>
    <div class="divide-y">
      <div>
        <div v-if="isAdmin">
          <div class="flex items-center mb-4">
            <div class="w-48">Is testing machine?</div>

            <div class="flex items-center space-x-2">
              <switch-toggle v-model="isTestingMachine" />
            </div>
          </div>
        </div>
        <div class="flex items-center mb-4">
          <div class="w-48">Is packing machine?</div>

          <div class="flex items-center space-x-2">
            <switch-toggle v-model="isPackingMachine" />
          </div>
        </div>
        <div class="flex items-center mb-4">
          <div class="w-48">Reporting Stats</div>

          <div
            class="text-gray-400"
            @click="resetReportingStats"
            :class="
              hasReset
                ? ' text-green-500'
                : 'text-indigo-500 hover:underline cursor-pointer'
            "
          >
            {{ hasReset ? 'Stats updated' : 'Update stats' }}
          </div>
        </div>

        <div v-if="isPackingMachine">
          <div class="flex items-center space-x-2 mb-4">
            <div class="font-semibold">Printers</div>
            <div class="flex items-center space-x-2">
              <div
                class="w-2 h-2 flex-none rounded-full default-transition"
                :class="{
                  'bg-green-400': qzStatus === 'active',
                  'bg-yellow-400': qzStatus === 'connecting',
                  'bg-gray-400': qzStatus === 'inactive'
                }"
              ></div>
              <div class="text-gray-400">{{ qzStatus | capitalize }}</div>
            </div>
          </div>
          <set-printers v-if="qzActive" />
        </div>
      </div>

      <div class="mt-6" v-if="isAdmin && !isCustomerService">
        <div class="flex-col flex items-start mb-4 py-5">
          <div class="flex space-x-4 items-center w-full">
            <div
              class="text-xl flex-grow font-semibold py-5 items-center justify-between"
            >
              Admins
            </div>
          </div>
          <div class="w-full flex-grow -mx-2 divide-y">
            <div
              v-for="user in admins"
              :key="`packer-${user.id}`"
              class="group flex items-center py-2 hover:bg-gray-50"
            >
              <div class="px-4 flex-grow flex items-center">
                <avatar size="sm" :email="user.name" class="mr-4" />

                {{ user.name }}
              </div>

              <div class="flex space-x-2 px-4 pr-0">
                <switch-toggle
                  v-model="user.active"
                  @input="togglePacker(user)"
                />
              </div>
            </div>
          </div>
          <div class="flex space-x-4 items-center w-full mt-8">
            <div
              class="text-xl flex-grow font-semibold py-5 items-center justify-between"
            >
              Packers
            </div>
            <div class="flex items-center space-x-2">
              <div class="text-gray-500">Show inactive</div>
              <switch-toggle v-model="showInactive" />
            </div>
            <div>
              <add-packer />
            </div>
          </div>

          <div class="w-full sm:flex-grow relative flex items-center pb-4">
            <input
              type="text"
              class="w-full icon-left-input"
              v-model="searchTerm"
              placeholder="Search for packer..."
            />

            <div class="absolute left-2">
              <font-awesome-icon
                :icon="['fal', 'magnifying-glass']"
                class="text-gray-400 text-lg"
              />
            </div>
          </div>

          <div
            v-if="filteredPackers.length > 0"
            class="w-full flex-grow -mx-2 divide-y"
          >
            <div
              v-for="user in filteredPackers"
              :key="`packer-${user.id}`"
              class="group flex items-center py-2 hover:bg-gray-50"
              :class="user.active ? '' : 'text-gray-400'"
            >
              <div class="px-4 w-96 flex items-center">
                <avatar
                  size="sm"
                  :email="user.name"
                  class="mr-4"
                  :class="user.active ? '' : 'opacity-50'"
                />

                {{ user.name }}
              </div>
              <div
                class="px-6 text-left flex-grow"
                :class="user.active ? 'text-gray-400 ' : 'text-gray-300'"
              >
                {{ user.email }}
              </div>
              <div
                class="rounded p-1 px-2 text-xs font-medium uppercase font-mono"
                :class="user.active ? 'text-indigo-400 ' : 'text-gray-300'"
              >
                {{ user.active ? 'Active' : 'Inactive' }}
              </div>

              <div class="flex space-x-2 px-4 pr-0">
                <switch-toggle
                  v-model="user.active"
                  @input="togglePacker(user)"
                />
              </div>
            </div>
          </div>
          <div v-else class="p-8 text-center w-full text-gray-500">
            <div v-if="searchTerm">No results for "{{ searchTerm }}"</div>
            <div v-else>No active packers</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetPrinters from '@/components/common/printer/SetPrinters';
import { mapGetters } from 'vuex';
import Avatar from '@/components/common/ui/Avatar';
import SwitchToggle from '@/components/common/ui/SwitchToggle';
import { join, isEmpty, map, capitalize } from 'lodash/fp';

import utils from '@/utils';
import AddPacker from '@/components/common/settings/AddPacker.vue';
export default {
  components: {
    Avatar,
    SetPrinters,
    SwitchToggle,
    AddPacker
  },
  data() {
    return {
      hasReset: false,
      showInactive: false,
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService',
      qzStatus: 'getQzStatus',
      portalUsers: 'portalUsersGetter',
      isTestingMachine: 'isTestingMachine',
      packers: 'packersGetter',
      admins: 'adminUsersGetter'
    }),
    qzActive() {
      return this.qzStatus === 'active';
    },
    isPackingMachine: {
      get() {
        return this.$store.state.core.isPackingMachine;
      },
      set(value) {
        this.$store.commit('set_is_packing_machine', value);
      }
    },
    tableStyle() {
      let template = 'auto';
      for (let index = 0; index < 3; index++) {
        if (index !== 0) {
          template += ' max-content';
        }
      }

      return {
        gridTemplateColumns: template
      };
    },
    filteredPackers() {
      let filtered = this.packers;

      //if there is a search term filter on that as the name
      if (this.searchTerm) {
        filtered = filtered.filter(packer =>
          packer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        //if showInactive is false filter out inactive packers
        if (!this.showInactive) {
          filtered = filtered.filter(packer => packer.active);
        }
      }

      // order by name
      filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));

      return filtered;
    }
  },
  methods: {
    getRoles(roles) {
      if (isEmpty(roles[0])) {
        return '-';
      } else {
        const arr = map(capitalize, roles);
        return join(', ', arr);
      }
    },
    setTestingMachine(value) {
      this.$store.commit('set_is_testing_machine', value.target.checked);
    },
    togglePacker(user) {
      this.$store
        .dispatch('togglePacker', {
          id: user.id,
          status: user.active
        })
        .then(resp => {
          // this.$store.commit('update_portal_user', resp);
        })
        .catch(() => {});
    },
    resetReportingStats() {
      utils.removeStorage('historicalAnalytics', 'local');
      utils.removeStorage('historicalAnalytics6Months', 'local');
      this.hasReset = true;
      this.$store.dispatch('getHistoricalAnalytics');
      this.$store.dispatch('getHistoricalAnalytics');
      setTimeout(() => {
        this.hasReset = false;
      }, 3000);
    }
  },
  mounted() {}
};
</script>

<style lang="css" scoped></style>
