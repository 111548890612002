<template>
  <div>
    <div
      class="flex items-center"
      :class="{ 'flex-col justify-center text-center ': isCol }"
    >
      <div class="w-48" v-if="!isCol">Label Printer</div>
      <div class="w-96 h-16 flex items-center" v-if="defaultLabelPrinter">
        <transition name="fade" mode="out-in">
          <select-dropdown class="w-full" v-if="printers">
            <select
              id="defaultLabelPrinter"
              v-model="defaultLabelPrinter"
              @change="setPrinter($event, defaultLabelPrinter)"
            >
              <option
                :key="printer.name"
                v-for="printer in printers"
                :value="printer"
                >{{ printer.name }}</option
              >
            </select>
          </select-dropdown>
          <div
            v-else
            class="w-full  text-gray-400 flex flex-col space-y-4 items-center justify-center space-x-4"
          >
            <throbber color="dark" />
            <div>Searching for printers...</div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import qz from 'qz-tray';
import utils from '@/utils';
import SelectDropdown from '@/components/common/ui/SelectDropdown.vue';
import Throbber from '@/components/common/ui/Throbber.vue';

export default {
  components: { SelectDropdown, Throbber },
  props: {
    isCol: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      printers: null,
      defaultLabelPrinter:
        utils.getStorage('defaultLabelPrinter', 'local') || null
    };
  },
  mounted() {
    this.getPrinters();
  },
  methods: {
    getPrinters() {
      const self = this;

      qz.printers
        .details()
        .then(function(data) {
          console.log('got printers');
          self.printers = data;
          if (!self.defaultLabelPrinter) {
            self.$store.commit('set_default_printer', {
              type: 'defaultLabelPrinter',
              name: data[0]
            });
            self.defaultLabelPrinter = data[0];
          }
        })
        .catch(function(e) {
          console.log(e);
        });
    },
    setPrinter(evt, printer) {
      utils.setStorage(evt.target.id, printer, 'local');
      this.$store.commit('set_default_printer', {
        type: evt.target.id,
        name: printer
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
