<template>
  <div class="border rounded-lg p-4 w-full">
    <div class=" font-semibold text-lg mb-4 flex items-center">
      <div class="flex-grow">What's in orders...</div>
      <div class="font-normal text-gray-400 font-mono text-sm leading-none">
        NEW SUBSCRIBERS (LATEST {{ data.length }})
      </div>
    </div>

    <div class="space-y-4  ">
      <div class="text-gray-500 flex space-x-4 ">
        <div class="flex-grow  sm:flex-none sm:w-32">Average Cost</div>
        <span class="font-medium text-gray-900" v-if="averageCost">{{
          averageCost | formatPounds(2)
        }}</span>
      </div>
      <!-- <div
        v-for="(item, index) in data"
        :key="`item-${index}`"
        class="space-x-4 flex items-center"
      >
        <div class="w-24 flex-none text-right font-mono">
          {{ item.cost | formatPounds }}
        </div>
        <div class="flex items-center space-x-1">
          <div
            v-for="(key, value) in item.sku"
            :key="value"
            class="flex bg-gray-100 rounded-full space-x-1 items-center leading-none"
          >
            <div class="w-7 rounded-full overflow-hidden object-contain">
              <img :src="`/images/products/${getImage(value)}.jpg`" />
            </div>
            <div
              class="text-xs text-gray-400 font-mono pr-2 leading-non relative "
            >
              {{ getFormat(key, value) }}
            </div>
          </div>
        </div>
      </div> -->

      <div class="flex  flex-col space-y-2 h-96 w-full overflow-x-auto">
        <div
          v-for="item in totals"
          :key="`total-item-${item.sku}`"
          class=" flex space-x-2 items-center"
          :style="{ width: `${item.height}%`, minWidth: '37px' }"
        >
          <div class="w-64 flex-none ">
            <div>
              {{ item.name }} <span v-if="item.isKitten">(Kitten)</span>
            </div>
            <div class="font-mono text-gray-500 text-xs">{{ item.sku }}</div>
          </div>
          <div
            class=" flex space-x-2 items-center h-10 bg-gray-200 rounded-full !min-w-10 flex-grow"
          >
            <div class="w-10 rounded-full overflow-hidden object-contain">
              <img :src="`/images/products/${getImage(item.sku)}.webp`" />
            </div>
          </div>
          <div
            class="text-xs text-gray-500 font-mono text-center leading-non relative "
          >
            {{ formatThousands(item.quantity) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { snakeCase } from 'lodash/fp';
export default {
  name: 'LatestSubOrders',
  data() {
    return {
      data: [],
      totals: [],
      maxHeight: 100
    };
  },
  computed: {
    averageCost() {
      // work out the average cost of the orders
      const total = this.data.reduce((acc, item) => {
        return acc + item.cost;
      }, 0);
      return total / this.data.length;
    },
    ...mapGetters({
      products: 'getProducts'
    })
  },
  methods: {
    ...mapActions({
      getLatestSubOrders: 'getLatestSubOrders'
    }),
    getImage(sku) {
      return sku.includes('_BAG_') ? 'D_BAG' : sku;
    },
    getFormat(key, value) {
      if (value.includes('_BAG_')) {
        return key / 800;
      }
      return value.includes('_BAG_') ? key / 800 : key;
    },
    formatThousands(val) {
      return val.toLocaleString();
    }
  },
  mounted() {
    this.getLatestSubOrders().then(response => {
      this.data = response.data.content;
      // turn all the sku keys into snake case
      this.data.forEach(item => {
        item.sku = Object.keys(item.sku).reduce((acc, key) => {
          acc[snakeCase(key).toUpperCase()] = item.sku[key];
          return acc;
        }, {});
      });

      // total up all the skus from the orders
      const total = this.data.reduce((acc, item) => {
        Object.keys(item.sku).forEach(key => {
          if (acc[key]) {
            acc[key] += item.sku[key];
          } else {
            acc[key] = item.sku[key];
          }
        });
        return acc;
      }, {});

      // for each sku format the quantity
      Object.keys(total).forEach(key => {
        total[key] = this.getFormat(total[key], key);
      });
      // order totals by highest quantity and show their amounts
      const ordered = Object.keys(total)
        .sort((a, b) => total[b] - total[a])
        .map(key => {
          const product = this.products.find(p => p.sku === key);
          return {
            sku: key,
            quantity: total[key],
            name: product.name,
            isKitten: product.foodcategory === 'kitten'
          };
        });

      const maxHeight = ordered[0].quantity;

      // for each sku, get the percentage of the max height
      ordered.forEach(item => {
        item.height = (item.quantity / maxHeight) * 100;
      });

      this.totals = ordered;
    });
  }
};
</script>

<style></style>
