<template>
  <div class="flex space-x-1 items-center overflow-hidden w-full relative">
    <div
      v-for="cat in catsList"
      :key="`sub-cat-${cat.catId}`"
      class="bg-gray-200 rounded overflow-hidden flex-none"
    >
      <cat-image
        link
        class="flex-none flex w-12 h-12 aspect-square bg-gray-100"
        :cat="cat"
      />
      <!-- <img
        :src="getImageUrl(cat.image, 'tr=fo-cat,ar-1_1,w-36,f-webp')"
        class="aspect-square flex-none rounded-full flex"
      /> -->
    </div>
    <div
      class="w-48 bg-gradient-to-l from-white absolute right-0 top-0 bottom-0"
    ></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CatImage from './CatImage.vue';
export default {
  name: 'SubscriberCats',

  data() {
    return { catsList: [], selectedCat: null, isLoading: false };
  },
  components: { CatImage },
  methods: {
    ...mapActions({
      getCats: 'getCatsList'
    }),
    getImageUrl(image, config) {
      return `https://ik.imagekit.io/thecoolcatclub/${image}?${config}`;
    }
  },
  mounted() {
    this.getCats({
      params: { marketingAllowed: true, type: 'approved', order: 'desc' }
    })
      .then(response => {
        this.catsList = response;
      })
      .catch(error => {
        console.log(error.response.data);
      });
  }
};
</script>

<style></style>
