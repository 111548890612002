<template>
  <div class="border rounded-lg p-4 w-full">
    <div class=" font-semibold text-lg mb-4">Users Credit</div>
    <div v-if="usersList.length > 0">
      <div class="font-semibold text-xl">
        £{{ Number(totalCredit).toLocaleString() }}
      </div>
      <div class="text-gray-500 mt-1 font-mono text-xs">
        Total User Credit
      </div>
      <div class="grid   w-full   " :style="gridStyle">
        <div class=" table-head contents">
          <div></div>

          <div class="px-2">Total Amount</div>
        </div>
        <router-link
          tag="div"
          :to="{
            name: 'userProfile',
            params: { user: user, id: user.id, tab: 'activity' }
          }"
          v-for="user in creditUsers"
          :key="user.id"
          class="cursor-pointer group flex items-center py-2 hover:bg-gray-50 contents table-item "
        >
          <div class="px-2">{{ user.name }}</div>
          <div class="px-2 text-right">£{{ user.credit }}</div>
        </router-link>
        <div
          class="px-2 py-2 text-gray-500 hover:underline"
          @click="toggleShow"
          v-if="!showAll"
        >
          Show {{ moreLength }} more
        </div>
      </div>
    </div>
    <div v-else class=" h-24 text-gray-400 flex items-center justify-center">
      No users with credit
    </div>
  </div>
</template>

<script>
import { sumBy } from 'lodash/fp';
export default {
  name: 'UserCreditList',
  data() {
    return {
      usersList: [],
      showAll: false,
      showMax: 5
    };
  },
  computed: {
    totalCredit() {
      return sumBy(function(o) {
        return Number(o.credit);
      }, this.usersList);
    },
    gridStyle() {
      return {
        gridTemplateColumns: 'auto    minmax(min-content, max-content)'
      };
    },
    creditUsers() {
      if (!this.showAll) {
        console.log('dont show-all');
        console.log(this.usersList.slice(0, this.showMax));
        return this.usersList.slice(0, this.showMax);
      } else {
        console.log('show all');
        return this.usersList;
      }
    },
    moreLength() {
      return this.usersList.length - this.showMax;
    }
  },
  methods: {
    toggleShow() {
      console.log('showA;;');
      this.showAll = !this.showAll;
    }
  },
  mounted() {
    this.$store.dispatch('usersCreditList').then(response => {
      this.usersList = response.data.content;
    });
  }
};
</script>

<style></style>
