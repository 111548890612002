<template>
  <div
    ref="scrollWarapper"
    class="relative overflow-x-auto"
    v-if="dispatchCore && today"
  >
    <div class="sticky left-0 space-x-8 flex items-center">
      <div class="flex-grow">
        <div class="text-3xl font-semibold">
          {{
            activeHover
              ? activeHover.extraCount
                ? activeHover.totalCount
                : activeHover.count
              : today.count
          }}
        </div>
        <div class="text-gray-500 font-mono text-xs">
          {{ activeHover ? getDate(activeHover.day) : getDate(today.day) }}
        </div>
      </div>
    </div>
    <div
      class="  grid grid-rows-1 mt-6 block "
      style="grid-template-columns: repeat(31,minmax(auto,1fr))"
    >
      <div
        v-for="day in dispatchCore"
        :key="`day-${day.day}`"
        :id="`day-${day.day}`"
        style="grid-template-rows:auto 1fr auto;"
        class="h-36 flex-none grid justify-center   relative   w-9 group bg-gradient-to-t  pb-1 rounded "
        :class="{
          'from-indigo-200': day.isToday,
          'hover:from-indigo-100': day.day > today.day
        }"
        @mouseover.prevent="activeHover = day.day > today.day ? day : null"
        @mouseleave.prevent="activeHover = null"
      >
        <div class=" h-10 relative pb-2 grid items-start justify-center">
          <div
            class="text-indigo-500 absolute pb-2 text-xs w-full tracking-wide uppercase flex flex-col font-semibold  items-center justify-center  h-full flex flex-col items-center justify-end"
            v-if="day.isToday"
          >
            <div class="uppercase">
              Today
            </div>

            <div class="w-0.5 bg-indigo-200 flex-none h-5 mt-1 mb-0.5"></div>
          </div>
        </div>
        <div class="w-full  grid justify-center items-end ">
          <div
            class="w-7 mb-2 flex h-full  flex-col items-center justify-end  relative rounded  "
            :class="{ '': day.isToday }"
          >
            <div
              v-if="day.extraCount && day.day > today.day"
              class="  w-full  border-2  border-indigo-300  rounded-t overflow-hidden justify-end flex flex-col  "
              :class="day.count === 0 ? 'rounded-b' : ''"
              :style="`height:${day.extraHeight}%`"
            ></div>
            <div
              v-if="day.count !== 0 && day.day > today.day"
              class="  w-full  rounded overflow-hidden justify-end flex flex-col  "
              :class="{
                'bg-gray-300 group-hover:bg-indigo-300': day.day < today.day,
                'bg-indigo-300 ': day.day >= today.day,
                'bg-indigo-400': day.isToday,
                'rounded-t-none': day.extraCount
              }"
              :style="`height:${day.height}%`"
            ></div>
          </div>
        </div>
        <div class="flex-col flex flex-none justify-center w-full items-center">
          <div
            class=" text-xs"
            :class="{
              'text-indigo-500 font-semibold': day.isToday,
              'text-gray-400': day.day > today.day,
              'text-gray-200': day.day < today.day
            }"
          >
            {{ getDay(day.day) }}
          </div>
          <div
            class=" text-xs"
            :class="{
              'text-indigo-500 font-semibold': day.isToday,
              'text-gray-400': day.day > today.day,
              'text-gray-200': day.day < today.day
            }"
          >
            {{ getOrdinal(day.day) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { maxBy, find } from 'lodash/fp';
import moment from 'moment';
import utils from '@/utils';
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      dispatch: 'getDispatchPredictions'
    }),
    dispatchLatest() {
      const latest = this.dispatch;

      latest.forEach(d => {
        const day = this.getDay(d.day);

        if (day === 'Mo') {
          const pastTwoDays = latest.filter(
            day => day.day < d.day && day.day >= d.day - 2
          );

          const sum = pastTwoDays.reduce((acc, curr) => acc + curr.count, 0);
          d.totalCount = sum + d.count;
        }
      });

      return latest;
    },
    maxValue() {
      return maxBy(function(o) {
        return o.totalCount;
      }, this.dispatchLatest).totalCount;
    },
    today() {
      return find(['day', moment().date()], this.dispatchCore);
    },

    dispatchCore() {
      const days = this.dispatch;
      days.forEach(d => {
        d.isToday = moment().date() === d.day;

        const count = d.day < moment().date() ? 0 : d.count;
        const day = this.getDay(d.day);

        if (day === 'Mo') {
          const pastTwoDays = days.filter(
            day => day.day < d.day && day.day >= d.day - 2
          );
          const sum = pastTwoDays.reduce((acc, curr) => acc + curr.count, 0);
          d.extraCount = sum;
          d.extraHeight = utils.getPercentageOf(this.maxValue, sum);
        }
        d.height = utils.getPercentageOf(this.maxValue, count);
      });

      return days;
    }
  },
  mounted() {
    this.getDispatchPredictions().then(() => {
      this.$nextTick(() => {
        const day = document.getElementById(
          `day-${this.today.day > 1 ? this.today.day - 1 : this.today.day}`
        );

        const dayOffset = day.offsetLeft;

        this.$refs.scrollWarapper.scrollLeft = dayOffset;
      });
    });
  },
  methods: {
    ...mapActions({
      getDispatchPredictions: 'getDispatchPredictions'
    }),
    getOrdinal(val) {
      return moment.localeData().ordinal(val);
    },

    getDay(d) {
      return moment()
        .date(d)
        .format('dd');
    },
    getDate(d) {
      return moment()
        .date(d)
        .format('Do MMMM, YYYY');
    }
  },
  data() {
    return {
      activeHover: null
    };
  }
};
</script>

<style></style>
