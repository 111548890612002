<template>
  <div class="border rounded-lg p-4 w-full">
    <div class=" font-semibold text-lg mb-4">Trials Ending Today</div>
    <div v-if="usersList.length > 0">
      <div class="font-semibold text-xl">
        {{ usersList.length }}
      </div>
      <div class="text-gray-600">
        Total Users
      </div>
      <div class="grid   w-full   " :style="gridStyle">
        <div class=" table-head contents border-0">
          <div></div>
        </div>
        <router-link
          tag="div"
          :to="{
            name: 'userProfile',
            params: { user: user, id: user.userid, tab: 'activity' }
          }"
          v-for="user in usersList"
          :key="user.id"
          class="cursor-pointer group flex items-center py-2 hover:bg-gray-50 contents table-item "
        >
          <div class="px-2">{{ user.name }}</div>
        </router-link>
      </div>
    </div>
    <div v-else class=" h-24 text-gray-400 flex items-center justify-center">
      No trials ending today
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'UserCreditList',
  data() {
    return {
      today: {
        day: moment().format('D'),
        month: moment().format('M'),
        year: moment().format('YYYY')
      },

      usersList: []
    };
  },
  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: 'auto'
      };
    }
  },
  mounted() {
    this.$store.dispatch('usersTrialsEndingList', this.today).then(response => {
      this.usersList = response.data.content;
    });
  }
};
</script>

<style></style>
