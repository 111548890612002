<template>
  <div class="w-full h-6  flex bg-gray-100 rounded overflow-hidden">
    <div
      v-for="breakdown in filteredBreakdowns"
      :key="breakdown.key"
      :style="`width:${breakdown.percentage}%`"
      class="px-3 text-white h-full flex items-center text-xs"
      :class="breakdown.color"
    >
      {{ Number(breakdown.percentage).toFixed(1) }}%
    </div>
  </div>
</template>

<script>
import { filter } from 'lodash/fp';
import utils from '@/utils';
export default {
  props: {
    total: {
      type: [String, Number]
    },
    data: {
      type: [Array]
    }
  },
  computed: {
    filteredBreakdowns() {
      return filter(function(o) {
        return Number(o.value) !== 0;
      }, this.breakdowns);
    },
    breakdowns() {
      const splits = this.data;
      splits.forEach(d => {
        d.percentage = utils.getPercentageOf(this.total, Number(d.value));
        d.key = utils.createKey('breakdown', 6);
      });
      return splits;
    }
  }
};
</script>

<style lang="css" scoped></style>
